import Slider from "./components/slider";

import.meta.glob([ '@images/**', '!**/*@og.*' ])
import '@styles/app.sass'
import {createApp, reactive} from "petite-vue";
import {getCookie, setCookie} from "./utils/cookies";
import PhoneCodeInput from "./components/phone-code-input";
import {closestPreviousSibling} from "./utils/siblings";
import {isInViewport} from "./utils/is-in-viewport";

if (typeof localization === 'object') {
    const Localization = reactive({
        ...localization,
        get weglotReady() {
            if (!window.hasOwnProperty('Weglot')) return false
            return Weglot && Weglot.initialized
        },
        syncWeglotLang() {
            this.availableLanguages = {}
            this.lang = Weglot.getCurrentLang()
            Weglot.getAvailableLanguages()?.forEach(lang => {
                this.availableLanguages[lang] = Weglot.getLanguageName(lang)
            })
        },
        getLanguageName(code: string) {
            return !this.weglotReady ? this.names.lang : Weglot.getLanguageName(code)
        },
        get languageName() {
            return this.lang && this.getLanguageName(this.lang)
        },
        get regionName() {
            return localization.definition
                .find(item => item.region === this.region)?.name_native ?? ''
        },
        handleDropdownOnChange(select: EventTarget) {
            const dropdown = (select as HTMLSelectElement).closest('details')
            if (dropdown) dropdown.open = false
        },
        changeLanguage(e: Event) {
            this.weglotReady && Weglot.switchTo(this.lang)
            // TODO: přidat listenery na Weglot pro lepší kompatabilitu přepínače
            this.handleDropdownOnChange(e.target)
        },
        changeRegion(e: Event) {
            const url = localization.definition
                .find(item => item.region === this.region)?.url ?? null

            url && location.assign(url)
            this.handleDropdownOnChange(e.target)
        }
    })

    document.querySelectorAll('[data-localization-switcher]')
        .forEach(el => createApp({ Localization }).mount(el))

    createApp({
        Localization,
        handleOutsideClick(e: Event) {
            const detail: HTMLDetailsElement = document.querySelector('[data-localization-dropdown]')
            if (detail.contains(e.target as HTMLElement)) return

            detail.open = false
        },
        setUpListeners(e: Event) {
            const detail = e.target as HTMLDetailsElement
            document[detail.open ? "addEventListener" : "removeEventListener"]('click', this.handleOutsideClick)

            const closer = detail.querySelector('button[data-dropdown-closer]')
            closer && closer[detail.open ? "addEventListener" : "removeEventListener"]('click', () => detail.open = false)
        }
    }).mount('[data-localization-dropdown]')

    if (Localization.weglotReady) {
        Localization.syncWeglotLang()
    } else if (window.hasOwnProperty('Weglot')) {
        Weglot.on('initialized', () => { Localization.syncWeglotLang() })
    }
}

createApp({
    opened: false,
    sticked: false,
    up: false,
    lastScroll: 0,
    handleOpenedHeader(state: boolean) {
        document.body.classList.toggle("u-hiddenOverflow", state)
    },
    handleOutsideClick(e: Event) {
        const detail: HTMLDetailsElement = document.querySelector('[data-user-actions-dropdown]')
        if (detail.contains(e.target as HTMLElement)) return

        detail.open = false
    },
    setUpUserDropdownListeners(e: Event) {
        const detail = e.target as HTMLDetailsElement
        document[detail.open ? "addEventListener" : "removeEventListener"]('click', this.handleOutsideClick)

        const closer = detail.querySelector('button[data-dropdown-closer]')
        closer && closer[detail.open ? "addEventListener" : "removeEventListener"]('click', () => detail.open = false)
    },
    handleStickyHeader() {
        const header = document.querySelector('header').firstElementChild as HTMLElement,
            infoBar = document.querySelector('[data-info-bar]') as HTMLElement

        const hidingOnScroll = () => {
            const current = window.scrollY,
                offsetTop = infoBar?.offsetHeight ?? 0

            if (Math.abs(this.lastScroll - current) < window.innerHeight) {
                this.sticked = current > offsetTop
                this.up = this.sticked && current > offsetTop + header.offsetHeight * 2 && current > this.lastScroll
            }

            this.lastScroll = current
        }

        this.lastScroll = window.scrollY
        hidingOnScroll()

        window.addEventListener('scroll', () => hidingOnScroll(), { passive: true })
    }
}).mount('[data-header]');

(() => {
    const infoBar = document.querySelector('[data-info-bar]') as HTMLElement
    if (!infoBar) return

    infoBar.querySelector('[data-info-bar-closer]')?.addEventListener('click', e => {
        const key = (e.target as HTMLButtonElement).dataset.key ?? '0';
        setCookie(`InfoBar-${key}`, 'closed', 365)
        infoBar.remove()
    })
})();

(() => {
    document.querySelectorAll<HTMLDialogElement>('[data-modal]').forEach(modal => {
        modal.querySelector('[data-modal-closer]')?.addEventListener("click", () => modal.close())
        modal.addEventListener("click", ev => ev.target === modal && modal.close())
    })

    const exitPopup = document.querySelector<HTMLDialogElement>('[data-exit-popup]')
    if (!exitPopup) return

    const exitPopupTracker = (e: MouseEvent) => {
        if (getCookie('offerInterest') !== '') return

        const shouldShowExitIntent = !e.relatedTarget && e.clientY < 10
        shouldShowExitIntent && exitPopup.showModal()
    };

    exitPopup.addEventListener('close', () => {
        document.removeEventListener('mouseout', exitPopupTracker)
        setCookie('ExitPopup', 'closed', 30)
    })

    setTimeout(() => {
        document.addEventListener('mouseout', exitPopupTracker);
    }, );
})();

(() => {
    document.querySelectorAll<HTMLDetailsElement>('details[data-dropdown]').forEach(dropdown => {
        const handleDropdownOutsideClick = (e: Event) => {
            if (dropdown.contains(e.target as HTMLElement)) return
            dropdown.open = false
        }

        dropdown.addEventListener("toggle", () => {
            document[dropdown.open ? "addEventListener" : "removeEventListener"]('click', handleDropdownOutsideClick)

            const closer = dropdown.querySelector('button[data-dropdown-closer]')
            closer && closer[dropdown.open ? "addEventListener" : "removeEventListener"]('click', () => dropdown.open = false)
        })
    })
})();

document.addEventListener('invalid', (e) => {
    const target = e.target as HTMLElement
    target.closest('form')?.classList.add('is-invalid')
}, true);

document.querySelectorAll('[data-enhanced-form]')
    .forEach(form => createApp({ PhoneCodeInput }).mount(form));

document.querySelectorAll('[data-slider]')
    .forEach(slider => createApp({ Slider }).mount(slider));

(() => { // Content
    document.querySelectorAll<HTMLButtonElement>('details button.ServiceDetails-closer')
        .forEach(button => button.addEventListener("click", () => {
            const details = button.closest('details')
            if (!details) return

            details.open = false

            const serviceHeading = closestPreviousSibling(details, 'hr') as HTMLElement
            !isInViewport(serviceHeading, 50) && serviceHeading.scrollIntoView()
        }))

    const timeline = document.querySelector<HTMLElement>('.ContentTimeline')
    if (!timeline) return

    const offsetTimeline = () => {
        const offsetTop = timeline?.offsetTop ?? 0,
            maxCount = parseInt(timeline.style.getPropertyValue('--TimelineEntriesCount')) - 1

        timeline.style.setProperty(
            '--TimelineOffset',
            `${-Math.min(Math.max(0, (window.scrollY - offsetTop) / window.innerHeight), maxCount)}`
        )
    }

    offsetTimeline()
    window.addEventListener("scroll", offsetTimeline)
})();

document.addEventListener("DOMContentLoaded", () => {

    (() => { // DataLayer
        // @ts-ignore
        window.hasOwnProperty('html_forms') && html_forms.on('success', (formElement: HTMLFormElement) => {
            if (!formElement) return
            const formData = new FormData(formElement)

            formElement.id === 'form-offer-interest' && setCookie('offerInterest', 'sent');

            if (!window.hasOwnProperty('dataLayer')) return

            let phoneCode = formData.get('your-phone-code').toString()
            if (phoneCode) {
                if (!phoneCode.startsWith("+")) phoneCode = `+${phoneCode}`
                const phone = formData.get('your-phone').toString().replace(phoneCode, '')
                formData.set('your-phone', (!phone.startsWith('+') ? phoneCode : '') + phone)
            }

            dataLayer?.push(formElement.id !== 'form-offer-interest' ? {
                event: `${formElement.dataset.slug}-send`
            } : {
                event: "offer-form-send",
                form_id: formElement.dataset.slug,
                ...(offerAnalyticalData ?? {}),
                candidate: {
                    email: formData.get('your-email'),
                    name: formData.get('your-firstname'),
                    surname: formData.get('your-surname'),
                    phone: formData.get('your-phone').toString().replace(' ', '')
                }
            })
        });

        if (!window.hasOwnProperty('dataLayer')) return

        document.body.classList.contains("blog") && dataLayer?.push({ page_type: "Blog" })

        document.body.classList.contains("single-post") && dataLayer?.push({
            article_name: document.querySelector('h1')?.textContent ?? '',
            article_category: document.querySelector('.PageHero-tag')?.textContent ?? ''
        })

        document.body.classList.contains("page") && dataLayer?.push({
            page_type: "Stránka",
            page_name: document.querySelector('h1')?.textContent ?? '',
        })
    })()
})
